'use client';

import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import { ComponentProps, forwardRef } from 'react';

type LinkProps = ComponentProps<typeof NextLink>;

export const LinkWithPreload = forwardRef<HTMLAnchorElement, LinkProps>(({ href, children, ...props }, ref) => {
  const router = useRouter();
  const strHref = href.toString();
  return (
    <NextLink
      {...props}
      ref={ref}
      href={strHref}
      prefetch={false}
      onMouseEnter={(e) => {
        if (strHref.startsWith('/') && props.prefetch !== false) {
          router.prefetch(strHref);
        }
        return props.onMouseEnter?.(e);
      }}
    >
      {children}
    </NextLink>
  );
});

LinkWithPreload.displayName = 'LinkWithPreload';
