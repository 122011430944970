type PushToDataLayerConfig = {
  throttleWait: number;
  key: string | null;
};

const defaultConfig: PushToDataLayerConfig = {
  throttleWait: 500,
  key: null,
};

let throttles: string[] = [];

/**
 * Push data to GTM's data layer
 * @param data Object
 */
export const pushToDataLayer = (data: Object, config: Partial<PushToDataLayerConfig> = {}) => {
  const combinedConfig = { ...defaultConfig, ...config };
  const key = combinedConfig.key ?? JSON.stringify(data);

  if (typeof window !== 'undefined') {
    // Initialise dataLayer if needed
    if (typeof window.dataLayer === 'undefined') {
      window.dataLayer = [];
    }
    if (throttles.indexOf(key) < 0 || combinedConfig.throttleWait === 0) {
      // Push data to the dataLayer array
      window.dataLayer.push(data);
      throttles.push(key);
      if (combinedConfig.throttleWait !== 0) {
        setTimeout(() => {
          throttles = throttles.filter((value) => value === key);
        }, combinedConfig.throttleWait);
      }
    } else if (process.env.APP_ENVIRONMENT !== 'production') {
      /* eslint-disable no-console */
      console.group('GTM event deduplicated');
      console.log(data);
      console.log(
        `If the event isn't intended to be deduplicated, please update the throttleWait config for your event`,
      );
      console.groupEnd();
      /* eslint-enable no-console */
    }
  }
};

export const dataLayerResetThrottles = () => {
  throttles = [];
};
