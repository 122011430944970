import NextLink from 'next/link';
import { ComponentProps, forwardRef } from 'react';
import { getLinkType } from 'src/general/helpers/getLinkType';
import { LinkWithPreload } from './LinkWithPreload';

type LinkProps = ComponentProps<typeof NextLink>;

/**
 * A component for both internal & external links.
 * External & anchor links are rendered as plain <a> tags; Internal links will use the Next JS component.
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(({ href, children, scroll, ...props }, ref) => {
  const linkType = getLinkType(href.toString());

  switch (linkType) {
    case 'external':
      return (
        <a href={href.toString()} target="_blank" rel="noopener noreferrer" ref={ref} {...props}>
          {children}
        </a>
      );
    case 'anchor':
      return (
        <a href={href.toString()} ref={ref} {...props}>
          {children}
        </a>
      );
    case 'internal':
    default:
      return (
        <LinkWithPreload href={href.toString()} {...props} ref={ref}>
          {children}
        </LinkWithPreload>
      );
  }
});

Link.displayName = 'Link';
