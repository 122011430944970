import { ProfileApiResponse } from 'src/data/ProfileApi/ProfileApi.types';
import { carmaCsrAuthClient } from './instance/carmaCsrAuthClient';
import { carmaCsrClient } from './instance/carmaCsrClient';
import { ProfileUpdatePayload, SignupPayload, UserResponse, VerificationCodeResponse } from './type/AuthService.type';

// Using any in this file because it out of scope TECH-6539
const AuthService = {
  async signUp(formData: SignupPayload): Promise<any> {
    return carmaCsrClient.post('customer/signup', { json: formData }).json();
  },

  async signIn(formData: any): Promise<any> {
    return carmaCsrClient.post('auth/password', { json: formData }).json();
  },

  async getProfile(): Promise<ProfileApiResponse> {
    return carmaCsrAuthClient.get('me/profile', { retry: 3 }).json();
  },

  async updateProfile(data: ProfileUpdatePayload) {
    return carmaCsrAuthClient.patch('me/profile', { json: data }).json();
  },

  async financeRate(data: any) {
    return carmaCsrClient.post('me/finance/rate', { json: data }).json();
  },

  async verify(formData: any) {
    return carmaCsrClient.patch('customer/verification', { json: formData }).json();
  },

  async resendVerify(formData: any) {
    return carmaCsrClient.post('customer/verification', { json: formData }).json();
  },

  async forgotPassword(formData: any) {
    return carmaCsrClient.post('password/token', { json: formData }).json();
  },
  async createNewPassword(formData: any) {
    return carmaCsrClient.post('password/reset', { json: formData }).json();
  },
  async createNewPasswordWithToken(formData: any): Promise<any> {
    return carmaCsrClient.post('password/reset', { json: formData }).json();
  },

  async getUserByEmail(email: string): Promise<UserResponse> {
    return carmaCsrClient.get(`customer/email/${email}`).json();
  },

  async getPhoneVerificationCode(phone: string): Promise<VerificationCodeResponse> {
    return carmaCsrClient
      .post(`customer/verify`, {
        json: { phone },
      })
      .json();
  },

  async checkPhoneNumberIsTaken(phone: string): Promise<{}> {
    return carmaCsrClient.post('customer/phone', { json: { phone } }).json();
  },
};

interface AuthToken {
  recaptchaToken?: string;
  apiKey?: string;
}

export const validateEmail = async (email: string, config: AuthToken) => {
  const headers = config.recaptchaToken
    ? { 'X-Recaptcha-Token': config.recaptchaToken }
    : config.apiKey
      ? { 'X-Api-Key': config.apiKey }
      : { 'X-Recaptcha-Token': '' };
  const json = await carmaCsrClient
    .post('email/validations', {
      json: {
        email,
      },
      headers,
    })
    .json();
  return json as {
    valid: boolean;
    suggestion?: string;
    block?: boolean;
  };
};

export default AuthService;
