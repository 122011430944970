import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FC, useEffect } from 'react';
import useSWR, { Fetcher } from 'swr';
import Cookies from 'universal-cookie';

import { COOKIE_USER_GROUP } from 'src/constants';
import AuthService from 'src/services/AuthService';
import { getOrdersIgnoreError } from 'src/services/OrderServiceClient';
import { authStore, getUserAuthDataFromCookies } from 'src/stores/authStore';
import { pushToDataLayerWithEnhancedConversion } from 'src/utils/pushToDataLayerWithEnhancedConversion';

const DEFAULT_FOCUS_THROTTLE = 60 * 1000;

const cookies = new Cookies();

export const AuthManager: FC = () => {
  const {
    isUserLoggedIn,
    userData,
    userAuthDataLoading,
    setUserDataLoading,
    setUserAuthData,
    setUserData,
    setUserOrdersLoading,
    setUserOrders,
  } = authStore((state) => ({
    isUserLoggedIn: state.isUserLoggedIn,
    userData: state.userData,
    userAuthDataLoading: state.userAuthDataLoading,
    setUserDataLoading: state.setUserDataLoading,
    setUserAuthData: state.setUserAuthData,
    setUserData: state.setUserData,
    setUserOrdersLoading: state.setUserOrdersLoading,
    setUserOrders: state.setUserOrders,
  }));
  const ldClient = useLDClient();

  useEffect(() => {
    const userAuthData = getUserAuthDataFromCookies();
    setUserAuthData(userAuthData);
  }, []);

  useEffect(() => {
    const ldContext = ldClient?.getContext();

    let newContextKey = cookies.get(COOKIE_USER_GROUP);
    if (userData?.email?.includes('carma.com.au') && isUserLoggedIn) {
      newContextKey = 'user-carma';
    }
    // Only update the context if needed
    if (ldContext && ldContext.key && ldContext.key !== newContextKey) {
      ldClient?.identify({
        key: newContextKey,
        kind: 'user',
      });
    }
  }, [userData, isUserLoggedIn]);

  const loadUserProfile: Fetcher<Awaited<ReturnType<typeof AuthService.getProfile> | null>, {}> = async () => {
    setUserDataLoading();
    try {
      // Load the user's profile
      const result = await AuthService.getProfile();
      setUserData(result);
      hubspotIdentifyUser(result.email);
      gtmIdentifyUser(result.email, result.phone);
      return result;
    } catch (_error) {
      // If it fails, log out
      setUserData(null);
      window.location.href = `/api/auth/signout?redirect=${encodeURIComponent(window.location.pathname)}`;
    }

    return null;
  };

  const loadOrdersData: Fetcher<Awaited<ReturnType<typeof getOrdersIgnoreError>>, {}> = async () => {
    setUserOrdersLoading();
    // Orders API call
    const result = await getOrdersIgnoreError();
    setUserOrders(result ?? []);
    return result;
  };

  useSWR(isUserLoggedIn && !userAuthDataLoading ? 'AuthManager-/me/profile' : null, loadUserProfile, {
    revalidateOnFocus: true,
    focusThrottleInterval: DEFAULT_FOCUS_THROTTLE,
  });

  // Handle loading and refreshing of the cart API data
  useSWR(isUserLoggedIn && userData && !userAuthDataLoading ? 'AuthManager-/me/orders' : null, loadOrdersData, {
    revalidateOnFocus: true,
    focusThrottleInterval: DEFAULT_FOCUS_THROTTLE,
  });

  return null;
};

const hubspotIdentifyUser = (email: string) => {
  let _hsq = (window._hsq = window._hsq ?? []);
  _hsq.push(['identify', { email }]);
};

const gtmIdentifyUser = (email: string, phone: string = '') => {
  pushToDataLayerWithEnhancedConversion(email, phone, {
    event: 'user_authenticated',
  });
};
