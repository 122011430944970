import { isEmpty } from 'lodash';
import { getPhoneNumberE164 } from 'src/general/helpers/getPhoneNumberE164';
import { getSha256Hash } from 'src/general/helpers/getSha256Hash';
import { pushToDataLayer } from './pushToDataLayer';

export const pushToDataLayerWithEnhancedConversion = async (email: string, phone: string = '', data: Object = {}) => {
  const promises = [getSha256Hash(email)];
  if (!isEmpty(phone)) {
    promises.push(getSha256Hash(getPhoneNumberE164(phone)));
  }
  const [sha256_email_address, sha256_phone_number] = await Promise.all(promises);

  const enhancedConversionData = {
    sha256_email_address,
    sha256_phone_number,
    ...data,
  };

  pushToDataLayer(enhancedConversionData);
};
