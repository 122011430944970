import { CAR_PURCHASE_STATUS } from 'src/constants';

import { PageProductListingFilters } from '../data/Contentful/ContentfulGetPageProductListing.types';
import { HotspotData, ProductDetailsPayloadImages } from './ProductDetail.type';

export const PRODUCT_CATALOGUE_LIMIT = 36;

export interface ProductListDataRow {
  sku: string;
  price: string;
  title: string;
  variant: string;
  buildYear: number;
  transmission: string;
  fuelType: string;
  kilometres: string;
  card: string;
  dutyPrice: string;
  defaultRate: number;
  weeklyRepayment: number;
  isLoading?: boolean;
  id: string;
  status: CAR_PURCHASE_STATUS;
  images: ProductDetailsPayloadImages;
  // eslint-disable-next-line camelcase
  search_keywords: string[];
  make: string;
  model: string;
  colour: string;
  body: string;
  ancapRating: string;
  watchers?: number;
  hotspots?: HotspotData;
}

interface DeliveryAddress {
  city: string;
  country: string;
  postalCode: string;
  state: string;
  streetName: string;
  streetNumber: string;
}

export enum StatusResponse {
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

interface Vehicle {
  sku?: string;
  id?: string;
}

export enum StateOrder {
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
}

export interface OrdersResponse {
  deliveryDate: string | null;
  deliveryAddress: DeliveryAddress;
  deliveryState: string;
  deliveryTime: string | null;
  id: string;
  orderNumber: string;
  totalPrice: number;
  state: StateOrder;
  vehicle: Vehicle;
  deliveryDateTimeFrom?: string;
  pickUpFlag?: boolean;
}

interface Address {
  city: string;
  country: string;
  postalCode: string;
  streetName: string;
  state: string;
  streetNumber: string;
  licenceNumber?: string;
  stateOfIssue?: string;
}

interface Customer {
  ABN: string;
  companyName: string;
  dateOfBirth: string;
  firstName: string;
  id: string;
  lastName: string;
  middleName: string;
  vehicleUsage: string;
  licence: Address;
}

interface Name {
  ['en-AU']: string;
}

interface StateId {
  id: string;
  typeId: string;
}

interface State {
  quantity: number;
  state: StateId;
}

interface ProductType {
  id: string;
  typeId: string;
  version: number;
}

interface SupplyChannel {
  id: string;
  typeId: string;
}

interface DisCount {
  id: string;
  value: string;
}

interface Value {
  centAmount: number;
  currencyCode: string;
  type: string;
  fractionDigits: number;
}

interface Discounted {
  discount: DisCount;
  value: Value;
}

interface Price {
  discounted: Discounted;
  value: Value;
  id: string;
}

interface ValueAttributes {
  key: string;
  label: string;
}

interface Attributes {
  name: string;
  value: ValueAttributes | number | string;
}

interface Variant {
  assets: any[];
  images: any[];
  id: number;
  sku: string;
  attributes: Attributes[];
  prices: Price[];
  availability: any;
}

export interface ProductCart {
  addedAt: string;
  id: string;
  lastModifiedAt: string;
  lineItemMode: string;
  priceMode: string;
  productId: string;
  productKey: string;
  quantity: number;
  name: Name;
  state: State[];
  productType: ProductType;
  supplyChannel: SupplyChannel;
  price: Price;
  variant: Variant;
}

interface TotalPrice {
  centAmount: number;
  fractionDigits: number;
  currencyCode: string;
  type: string;
}

export interface CartResponse {
  backoff: string;
  cartState: string;
  deliveryDate: string;
  deliveryNotes: string;
  deliveryTime: string;
  financierContactEmail: string;
  financierContactPhone: string;
  financierContactName: string;
  financierIsCarma: string;
  id: string;
  billingAddress: Address;
  customer: Customer;
  key: string;
  shippingAddress: Address;
  vehicle: Vehicle;
  totalPrice: TotalPrice;
  lineItems: ProductCart[];
}

export interface ProductListDataPayload {
  total: number;
  results: ProductListDataRow[];
  recommendation_id?: string | null;
}

export interface ServiceHistory {
  serviceDate: Date;
  kilometres: number;
  recordFormat: RecordFormat;
  provider: string;
}

export enum RecordFormat {
  Logbook = 'logbook',
}

export const enum FilterValues {
  Make = 'make',
  Model = 'model',
  CashMin = 'cashMin',
  CashMax = 'cashMax',
  FinanceMin = 'financeMin',
  FinanceMax = 'financeMax',
  YearMin = 'yearMin',
  YearMax = 'yearMax',
  KMMin = 'kmMin',
  KMMax = 'kmMax',
  EnginePowerMin = 'enginePowerMin',
  EnginePowerMax = 'enginePowerMax',
  FuelConsumptionMin = 'fuelConsumptionMin',
  FuelConsumptionMax = 'fuelConsumptionMax',
  Transmission = 'transmission',
  HighlightedFeatures = 'highlightedFeatures',
  FuelType = 'fuelType',
  BodyType = 'bodyType',
  BodySize = 'bodySize',
  DriveType = 'driveType',
  Seats = 'seats',
  Doors = 'doors',
  Colour = 'colour',
  Ancap = 'ancap',
  Co2emissions = 'co2emissions',
  Keyword = 'keyword',
  State = 'state',
  promotionTags = 'search_keywords',
}

interface ImageObject {
  url?: string;
}

export interface MarketingTile {
  title: string;
  textColour: string[];
  image: ImageObject;
  primaryCtaLabel?: string;
  primaryCtaUrl?: string;
  isDefaultTile: boolean;
  secondaryCtaLabel?: string;
  secondaryCtaUrl?: string;
  position: number;
  pageFiltersList?: PageProductListingFilters[];
}

export interface FinanceQuotesResponse {
  count: number;
  results: QuotesCard[];
}

export interface QuotesCard {
  id: string;
  financier: string;
  rate: number;
  repaymentAmountCents: number;
  termMonths: number;
  createdAt: Date;
  buildYear: string;
  loanAmountCents: number;
  vehiclePriceCents: number;
  fees: QuotesFees;
  reCalculatedRepaymentAmountCents: number | null;
  discountedRate: number | null;
  loanType: string;
  quoteRequestId: string;
}

interface QuotesFees {
  brokerFeeCents: number;
  establishmentFeeCents: number;
  ppsrFeeCents: number;
  repaymentFeeCents: number;
  discountedBrokerFeeCents: number | null;
}

export interface UnavailableProductListDataPayload {
  total: number;
  results: UnavailableProductListDataRow[];
}

export interface UnavailableProductListDataRow {
  sku: string;
  status: CAR_PURCHASE_STATUS;
}
