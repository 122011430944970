import(/* webpackMode: "eager" */ "/home/runner/work/carma-ui-web/carma-ui-web/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/carma-ui-web/carma-ui-web/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/carma-ui-web/carma-ui-web/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/carma-ui-web/carma-ui-web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/carma-ui-web/carma-ui-web/node_modules/next/font/local/target.css?{\"path\":\"src/general/helpers/fontsArmin.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/armingrotesk-regular-webfont.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./fonts/armingrotesk-semibold-webfont.woff2\",\"style\":\"normal\",\"weight\":\"600\"},{\"path\":\"./fonts/armingrotesk-ultrabold-webfont.woff2\",\"style\":\"normal\",\"weight\":\"800\"}],\"display\":\"swap\",\"variable\":\"--font-armin\",\"declarations\":[{\"prop\":\"ascent-override\",\"value\":\"90%\"}]}],\"variableName\":\"arminFont\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/carma-ui-web/carma-ui-web/node_modules/next/font/local/target.css?{\"path\":\"src/general/helpers/fontsCarmaTitle.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/CarmaTitleCompact-ExtraBold.woff2\",\"style\":\"normal\",\"weight\":\"800\"}],\"display\":\"swap\",\"variable\":\"--font-carmatitle\"}],\"variableName\":\"carmaTitleFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ThirdPartyLibraries"] */ "/home/runner/work/carma-ui-web/carma-ui-web/src/general/components/ThirdPartyLibraries/ThirdPartyLibraries.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebsiteHelpers"] */ "/home/runner/work/carma-ui-web/carma-ui-web/src/general/components/WebsiteHelpers/WebsiteHelpers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProviders"] */ "/home/runner/work/carma-ui-web/carma-ui-web/src/general/providers/StoreProviders/StoreProviders.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/carma-ui-web/carma-ui-web/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/carma-ui-web/carma-ui-web/styles/helperClasses.scss");
