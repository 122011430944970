import { clsx } from 'clsx';
import { ComponentProps, FC, HTMLAttributeAnchorTarget, MouseEventHandler, ReactNode } from 'react';
import { Link } from 'src/general/components/Link/Link';
import { getLinkType } from 'src/general/helpers/getLinkType';
import { CircularProgress } from '../CircularProgress/CircularProgress';
import { Shade } from '../Shade/Shade';
import classes from './Button.module.scss';

type Props = Prettify<
  {
    children: ReactNode;
    className?: string;
    /** The color and style of the button */
    variant?: 'accent' | 'primary' | 'secondary' | 'tertiary' | 'link';
    /** The size of the button */
    size?: 'large' | 'medium' | 'small' | 'xsmall';
    disabled?: boolean;
    /** Display a loading state for the button. This will also disable the button */
    isLoading?: boolean;
    /** Disable the button while its loading true by default */
    isDisabledWhileLoading?: boolean;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    title?: string;
    fullWidth?: boolean;
    'data-testid'?: string;
    id?: string;
    type?: HTMLButtonElement['type'];
    tabindex?: number;
    replace?: boolean;
    shallow?: boolean;
    prefetch?: boolean;
  } & (
    | {
        href: string;
        target?: HTMLAttributeAnchorTarget;
        rel?: string;
        onClick?: MouseEventHandler<HTMLAnchorElement>;
        forceATag?: boolean;
      }
    | {
        href?: undefined;
        onClick?: MouseEventHandler<HTMLButtonElement>;
      }
  )
>;

export const Button: FC<Props> = ({
  children,
  className,
  size = 'medium',
  variant = 'primary',
  disabled,
  isLoading,
  isDisabledWhileLoading = true,
  startIcon,
  endIcon,
  fullWidth,
  replace,
  shallow,
  prefetch,
  ...props
}) => {
  if (typeof props.href !== 'undefined') {
    const linkType = getLinkType(props.href);

    // Custom overwrite for external links
    if (linkType === 'external' && typeof props.target === 'undefined') {
      props.target = '_blank';
      props.rel = 'noopener noreferrer';
    }
  }

  const disabledStatus = disabled || (isLoading && isDisabledWhileLoading);

  const commonProps = {
    className: clsx('button', 'shade_container', classes.root, `button--${size}`, `button--${variant}`, className, {
      [classes.isLoading]: isLoading,
      [classes.disabled]: disabledStatus,
      [classes.fullWidth]: fullWidth,
    }),
  };

  const contentProps: Omit<ComponentProps<typeof ButtonContent>, 'children'> = {
    isLoading,
    startIcon,
    endIcon,
    variant,
  };

  if (typeof props.href !== 'undefined') {
    const { forceATag, ...linkProps } = props;
    if (forceATag) {
      return (
        <a {...commonProps} {...linkProps}>
          <ButtonContent {...contentProps}>{children}</ButtonContent>
        </a>
      );
    }
    return (
      <Link {...commonProps} {...linkProps} replace={replace} prefetch={prefetch} shallow={shallow} scroll>
        <ButtonContent {...contentProps}>{children}</ButtonContent>
      </Link>
    );
  }

  return (
    <button type="button" {...commonProps} {...props} disabled={disabledStatus}>
      <ButtonContent {...contentProps}>{children}</ButtonContent>
    </button>
  );
};

const hasShading = (variant: Props['variant']): boolean =>
  !!variant && ['accent', 'primary', 'secondary', 'tertiary'].indexOf(variant) >= 0;

export const ButtonContent: FC<Pick<Props, 'children' | 'isLoading' | 'startIcon' | 'endIcon' | 'variant'>> = ({
  children,
  isLoading,
  startIcon,
  endIcon,
  variant,
}) => (
  <>
    {hasShading(variant) && <Shade />}
    <span className="content">
      {startIcon}
      <span className="contentLabel">{children}</span>
      {endIcon}
    </span>
    {isLoading && (
      // Added global class to allow easier targeting of the container from outside the button
      <span className={clsx(classes.loadingContainer, 'loadingContainer')} data-testid="LOADING_BUTTON">
        <CircularProgress className={classes.progress} />
      </span>
    )}
  </>
);
